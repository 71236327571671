import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useGetCountryFromUrl } from '../../hooks';
import useGenericGetExecutives from '../../hooks/useGenericGetExecutive';

const CollectorExecutiveFilter = ({ setCollectorExecutiveCallback }) => {  
  const country = useGetCountryFromUrl()?.name || '';
  const { executives: collectionExecutives, loading } = useGenericGetExecutives(
    {
      groups_Name: 'collection',
      groups_Name_Iexact: country,
    }
  );

  const options = [
    { value: 'All', label: 'Todos los ejecutivos' },
    ...collectionExecutives.map(exec => ({
      value: exec.node.email,
      label: exec.node.completeName
    }))
  ];

  const [selectedValue, setSelectedValue] = useState('All');
  
  useEffect(() => {
    setCollectorExecutiveCallback('');
  }, []);
  
  const setCollectorExecutive = (event) => {
    const newValue = event.target.value;
    setSelectedValue(newValue);
    setCollectorExecutiveCallback(newValue === 'All' ? '' : newValue)
  };

  if (loading) {
    return (
      <FormControl sx={{ m: 1, minWidth: 115, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress size={24} />
      </FormControl>
    );
  }

  return (
    <FormControl sx={{ m: 1, minWidth: 115 }}>
      <Select
        name="filterType"
        value={selectedValue}
        onChange={setCollectorExecutive}
        displayEmpty
      >
        {options.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

CollectorExecutiveFilter.propTypes = {
  setCollectorExecutiveCallback: PropTypes.func,
};

CollectorExecutiveFilter.defaultProps = {
  setCollectorExecutiveCallback: () => {},
};

export default CollectorExecutiveFilter;
